 
<template>
    <div class="card mb-5 mb-xl-10">
      <div class="card-body">
        <!-- search input -->
        <button type="button" class="btn btn-success btn-sm mb-2 mr-2" size="sm" style="float: left; background-color:#030A8C; border-color: #030A8C" v-b-modal.modal-tambah><feather-icon
          icon="PlusCircleIcon"
          class="mr-50"
        />Tambah</button>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="datauser"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item v-b-modal.modal-edit @click="getdata(props.row.id);">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deletedata(props.row.id)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','25','50']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>

      <!-- modal tambah -->
      <b-modal
        id="modal-tambah"
        scrollable
        title="Tambah Data"
        cancel-title="Close"
        ok-title="Accept"
        cancel-variant="outline-secondary"
      >
        <form
          ref="form"
          @submit.stop.prevent="tambahdata"
        >
          <b-form-group
            label="Nama"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="datapengguna.nama"
            />
          </b-form-group>

          <!-- provinsi -->
          <b-form-group
            label="Provinsi"
            label-for="register-provinsi"
          >
            <b-form-select @input="getkabkot(datapengguna.kode_prov)"  v-model="datapengguna.kode_prov">
              <option
                  v-for="(dataprovinsi, k) in dataprovinsi"
                  :key="k"
                  :value="dataprovinsi.id"
              >
                  {{ dataprovinsi.nama }}
              </option>
            </b-form-select>
          </b-form-group>

          <!-- kabupaten -->
          <b-form-group
            label="Kabupaten"
            label-for="register-kabupaten"
          >
            <b-form-select @input="getkecamatan(datapengguna.kode_kab)" v-model="datapengguna.kode_kab">
              <option
                  v-for="(datakabupaten, k) in datakabupaten"
                  :key="k"
                  :value="datakabupaten.id"
              >
                  {{ datakabupaten.nama }}
              </option>
            </b-form-select>
          </b-form-group>

          <!-- kecamatan -->
          <b-form-group
            label="Kecamatan"
            label-for="register-kecamatan"
          >
            <b-form-select v-model="datapengguna.kode_kec">
              <option
                  v-for="(datakecamatan, k) in datakecamatan"
                  :key="k"
                  :value="datakecamatan.id"
              >
                  {{ datakecamatan.nama }}
              </option>
            </b-form-select>
          </b-form-group>

          <!-- email -->
          <b-form-group
            label="Email"
            label-for="register-email"
          >
              <b-form-input
                id="register-email"
                v-model="datapengguna.email"
                name="register-email"
                placeholder="john@example.com"
              />
          </b-form-group>

          <!-- password -->
          <b-form-group
            label-for="register-password"
            label="Password"
          >
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                id="register-password"
                v-model="datapengguna.password"
                class="form-control-merge"
                type="password"
                placeholder="············"
              />
            </b-input-group>
          </b-form-group>

          <!-- Hak Akses -->
          <b-form-group
            label="Hak Akses"
            label-for="register-kecamatan"
          >
            <b-form-select v-model="datapengguna.hak_akses">
              <option
                  v-for="(datahakakses, k) in datahakakses"
                  :key="k"
                  :value="datahakakses.nama"
              >
                  {{ datahakakses.nama }}
              </option>
            </b-form-select>
          </b-form-group>
        </form>

        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="handleOk2"
            >
              Kirim
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- modal edit -->
      <b-modal
        id="modal-edit"
        scrollable
        title="Edit Data"
        cancel-title="Close"
        ok-title="Accept"
        cancel-variant="outline-secondary"
      >
        <form
          ref="form"
          @submit.stop.prevent="editdata"
        >
          <b-form-group
            label="Nama"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="datapengguna.nama"
            />
          </b-form-group>

          <!-- provinsi -->
          <b-form-group
            label="Provinsi"
            label-for="register-provinsi"
          >
            <b-form-select @input="getkabkot(datapengguna.id_prov)"  v-model="datapengguna.id_prov">
              <option
                  v-for="(dataprovinsi, k) in dataprovinsi"
                  :key="k"
                  :value="dataprovinsi.id"
              >
                  {{ dataprovinsi.nama }}
              </option>
            </b-form-select>
          </b-form-group>

          <!-- kabupaten -->
          <b-form-group
            label="Kabupaten"
            label-for="register-kabupaten"
          >
            <b-form-select @input="getkecamatan(datapengguna.id_kab)" v-model="datapengguna.id_kab">
              <option
                  v-for="(datakabupaten, k) in datakabupaten"
                  :key="k"
                  :value="datakabupaten.id"
              >
                  {{ datakabupaten.nama }}
              </option>
            </b-form-select>
          </b-form-group>

          <!-- kecamatan -->
          <b-form-group
            label="Kecamatan"
            label-for="register-kecamatan"
          >
            <b-form-select v-model="datapengguna.id_kec">
              <option
                  v-for="(datakecamatan, k) in datakecamatan"
                  :key="k"
                  :value="datakecamatan.id"
              >
                  {{ datakecamatan.nama }}
              </option>
            </b-form-select>
          </b-form-group>

          <!-- email -->
          <b-form-group
            label="Email"
            label-for="register-email"
          >
              <b-form-input
                id="register-email"
                v-model="datapengguna.email"
                name="register-email"
                placeholder="john@example.com"
              />
          </b-form-group>

          <!-- password -->
          <b-form-group
            label-for="register-password"
            label="Password"
          >
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                id="register-password"
                v-model="password"
                class="form-control-merge"
                type="password"
                placeholder="············"
              />
            </b-input-group>
          </b-form-group>

          <!-- Hak Akses -->
          <b-form-group
            label="Hak Akses"
            label-for="register-kecamatan"
          >
            <b-form-select v-model="datapengguna.hak_akses">
              <option
                  v-for="(datahakakses, k) in datahakakses"
                  :key="k"
                  :value="datahakakses.nama"
              >
                  {{ datahakakses.nama }}
              </option>
            </b-form-select>
          </b-form-group>
        </form>

        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="handleOkedit"
            >
              Kirim
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BInputGroup
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import Vue from 'vue';
import VueLoading from 'vuejs-loading-plugin'
import axios from 'axios';

// import { VueEditor } from "vue2-editor";

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Loading', // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BInputGroup
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Nama',
          field: 'nama',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Kecamatan',
          field: 'kecamatan',
        },
        {
          label: 'Hak Akses',
          field: 'hak_akses',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      datauser:[],
      datapengguna:[],
      url: localStorage.getItem('baseapi'),
      pengguna: JSON.parse(localStorage.getItem('userData')),
      dataprovinsi:[],
      datakabupaten:[],
      datakecamatan:[],
      datahakakses:[],
      password:null,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.loaddata()
    this.getprovinsi()
    this.gethakakses()
  },
  methods: {
    editdata() {
      if(this.password == null){
        axios
            .put(
            this.url+`user/`+this.datapengguna.id,
            {
                 nama:this.datapengguna.nama,
                 email : this.datapengguna.email,
                 id_prov:this.datapengguna.id_prov,
                 id_kab:this.datapengguna.id_kab,
                 id_kec:this.datapengguna.id_kec,
                 hak_akses:this.datapengguna.hak_akses,
            }
            ,{
                headers:{
                // "xth": this.token
                }
            })
            .then(response => {
            
              this.loaddata()
              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Berhasil`,
                    },
                  })
            
              console.log(response.data)
              
              return response
              
            })
            .catch(error=>{
            console.log(error)
            
              return error
          })
      } else {
        axios
            .put(
            this.url+`user/`+this.datapengguna.id,
            {
                 nama:this.datapengguna.nama,
                 email : this.datapengguna.email,
                 password:this.password,
                 id_prov:this.datapengguna.id_prov,
                 id_kab:this.datapengguna.id_kab,
                 id_kec:this.datapengguna.id_kec,
                 hak_akses:this.datapengguna.hak_akses,
            }
            ,{
                headers:{
                // "xth": this.token
                }
            })
            .then(response => {
            
              this.loaddata()
              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Berhasil`,
                    },
                  })
            
              console.log(response.data)
              
              return response
              
            })
            .catch(error=>{
            console.log(error)
            
              return error
          })
      }
      // Hide the modal manually
      this.$nextTick(() => {
          this.$bvModal.hide('modal-edit')
      })
    },
    handleOk2(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.tambahdata()
    },
    handleOkedit(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.editdata()
    },
    getdata(id){
      axios.get(this.url+'user/'+id, {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        this.datapengguna = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    gethakakses(){
      axios.get(this.url+'hak_akses', {
      headers: {
          "Content-type": "application/json",
          }
      })
      .then(response => {
        this.datahakakses = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    deletedata(id){
      axios
            .delete(
            this.url+`user/${id}`,
            {
                headers: {
                    "Content-type": "application/json",
                    // "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Berhasil`,
                    },
                  })
                this.loaddata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            return err;
        });
    },
    tambahdata(){
          const nama=this.datapengguna.nama;
          const email = this.datapengguna.email;
          const password=this.datapengguna.password;
          const id_prov=this.datapengguna.kode_prov;
          const id_kab=this.datapengguna.kode_kab;
          const id_kec=this.datapengguna.kode_kec;
          const hak_akses=this.datapengguna.hak_akses;
        axios
            .post(
            this.url+"user",
            {
                nama,
                email,
                password,
                id_prov,
                id_kab,
                id_kec,
                hak_akses
            },
            {
            headers: {
                "Content-type": "application/json",
                // "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Berhasil`,
                    },
                  })
                this.loaddata()
            return res;
            })
            .catch((err) => {
            this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: err.response.data.message,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                    },
                  })
            return err;
        });
        
        this.$nextTick(() => {
          this.$bvModal.hide('modal-tambah')
        })
    },
    getprovinsi(){
      axios.get(this.url+'wilayah/provinsi', {
      headers: {
          "Content-type": "application/json",
          }
      })
      .then(response => {
        this.dataprovinsi = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    getkabkot(id){
      axios.get(this.url+'wilayah/kabupaten?id_prov='+id, {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      
      .then(response => {
        this.datakabupaten = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    getkecamatan(id){
      // var nama_ruang = '';
      // var i = 0;
      // console.log(this.datakabupaten)
      // for (i in this.datakabupaten) {
      //       if (this.datakabupaten[i].kode_kab == id) {
      //           nama_ruang = this.datakabupaten[i].id;
      //           console.log(nama_ruang); // {a: 5, b: 6}
      //       }
      //   }
      // console.log(nama_ruang)
      // console.log(id)
      // console.log('nama id')
      axios.get(this.url+'wilayah/kecamatan?id_kab='+id, {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        this.datakecamatan = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    loaddata(){
      this.$loading(true);
      axios.get(this.url+'user', {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        // const { users, total } = response.data.data
        this.datauser = response.data.data
        this.$loading(false);
      })
      .catch( error => {
        this.$loading(false);
        if (error.response.data === 'Token Tidak Valid/Hak akses tidak sesuai') {
          this.$swal({
            title: error.response.data,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
            }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem('userData')
              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            }
          })
        } else {
         this.$toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
      })
    },
  },
}
</script>